import "./CalculatorInputGroup.css";

function CalculatorInputGroup(props) {
    return (
        <div className="CalculatorInputGroup">
            <div className="CalculatorInputGroup-container">
                {props.children}
            </div>
        </div>
    );
}

export default CalculatorInputGroup;