import React from "react";
import "./Menu.css";
import MenuList from "./MenuList/MenuList";
import MenuItem from "./MenuList/MenuItem";
import logo from "./logo.png";

class Menu extends React.Component {
    constructor(props) {
        super(props);
    }

    handleMenuItemClick(page) {
        this.props.onChangeScreen(page);
    }

    render() {
        return (
            <div className="Menu">
                <div className="Menu-logo">
                    <img src={logo} alt="Purelight Power"/>
                </div>
                <MenuList>
                    <MenuItem
                        title="Net price per watt"
                        link="net-ppw"
                        onClick={this.handleMenuItemClick.bind(this)}/>
                    <MenuItem
                        title="Additional Work Fees"
                        link="additional-work"
                        onClick={this.handleMenuItemClick.bind(this)}
                    />
                </MenuList>
            </div>
        );
    }
}

export default Menu;