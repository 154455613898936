import React from "react";
import "./CalculatorInput.css";

class CalculatorNumericInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isValid: true
        };
    }

    handleChange(event) {
        console.log(event);
        this.changeValue(event.target.value);
    }

    handlePaste(event) {
        console.log(event);
        let pastedValue = (event.clipboardData || window.clipboardData).getData("text");
        this.changeValue(event.target.value + pastedValue);
    }

    changeValue(value) {
        let numericValue;
        if (typeof value == "string") {
            numericValue = parseFloat(value.replace(/,/g, ''));
        } else {
            numericValue = parseFloat(value);
        }
        if (value == "") {
            this.props.onChange(null);
        } else if (this.valueIsNumber(numericValue)) {
            this.props.onChange(numericValue);
            this.setState({ "isValid": true });
         } else {
            this.props.onChange(null);
            this.setState({ "isValid": false });
        }
    }

    valueIsNumber(value) {
        return typeof value == "number" && !isNaN(value);
    }

    render() {
        return (
            <div className={["CalculatorInput", this.state.isValid ? "valid" : "invalid"].join(" ")}>
                <input type="text" placeholder="" onChange={this.handleChange.bind(this)} onPaste={this.handlePaste.bind(this)}/>
            </div>
        );
    }
}

export default CalculatorNumericInput;