import React from "react";
import CalculatorSearchInput from "./CalculatorSearchInput";
import CalculatorSearchInputOption from "./CalculatorSearchInputOption";
import paymentMethods from "../data/paymentMethods.json";

class PaymentMethodSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: ""
        };
    }

    handleSearchChange(value) {
        this.setState({ "query": value });
    }

    getFilteredPaymentMethods() {
        let filtered = [];
        for (let paymentMethod of paymentMethods) {
            if (this.paymentMethodPassesFilter(paymentMethod)) {
                filtered.push(paymentMethod);
            }
        }
        return filtered;
    }

    paymentMethodPassesFilter(paymentMethod) {
        return this.queryIsValid() && this.matchesQuery(paymentMethod.name);
    }

    queryIsValid() {
        return this.state.query.length > 2;
    }

    matchesQuery(value) {
        let lowercaseValue = value.toLowerCase();
        let lowercaseQuery = this.state.query.toLowerCase();
        return lowercaseValue.includes(lowercaseQuery);
    }

    handleSelection(value) {
        this.setState({ "query": "" });
        this.props.onChange(value);
    }

    render() {
        return (
            <CalculatorSearchInput onChange={this.handleSearchChange.bind(this)} onSelect={this.handleSelection.bind(this)}>
                {this.getFilteredPaymentMethods().map(function(paymentMethod, index) {
                    return <CalculatorSearchInputOption key={index} label={paymentMethod.name} value={paymentMethod} />;
                })}
            </CalculatorSearchInput>
        );
    }
}

export default PaymentMethodSelection;