import React from "react";
import "./CalculatorResultGroup.css";

class CalculatorResultGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseDown: false
        };
    }

    getCurrency() {
        if (this.hasValue() && this.hasCurrency()) {
            return this.props.currency;
        }
        return "";
    }

    hasCurrency() {
        return this.props.currency != undefined && this.props.currency != null;
    }

    getValue() {
        if (this.hasValue()) {
            return Number(this.props.value).toLocaleString("en-US");
        }
        return "";
    }

    getUnits() {
        if (this.hasValue() && this.hasUnits()) {
            return this.props.units;
        }
        return "";
    }

    hasUnits() {
        return this.props.units != undefined && this.props.units != null;
    }

    hasValue() {
        return this.props.value != undefined && this.props.value != null && this.props.value != "";
    }

    copyToClipboard() {
        if (this.hasClipboardAccess() && this.hasValue()) {
            navigator.clipboard.writeText(this.props.value.toString());
        }
    }

    hasClipboardAccess() {
        return navigator != undefined && navigator.clipboard != undefined && navigator.clipboard.writeText != undefined;
    }

    handleMouseDown() {
        this.setState({ "isMouseDown": true });
    }

    handleMouseUp() {
        this.setState({ "isMouseDown": false });
    }

    render() {
        return (
            <div className="CalculatorResultGroup">
                <span className="CalculatorResultGroup-equals">=</span>
                <span className={["CalculatorResultGroup-result", this.state.isMouseDown ? "lighten" : ""].join(" ")} onClick={this.copyToClipboard.bind(this)} onMouseDown={this.handleMouseDown.bind(this)} onMouseUp={this.handleMouseUp.bind(this)}>
                    <span className="CalculatorResultGroup-currency noselect">{this.getCurrency()}</span>
                    <span className="CalculatorResultGroup-value noselect">{this.getValue()}</span>
                    <span className="CalculatorResultGroup-units noselect">{this.getUnits()}</span>
                </span>
            </div>
        );
    }
}

export default CalculatorResultGroup;