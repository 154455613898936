import React from "react";
import Calculator from "../Calculator";
import CalculatorInputGroup from "../CalculatorInputGroup";
import CalculatorInputGroupRow from "../CalculatorInputGroupRow";
import CalculatorInputGroupColumn from "../CalculatorInputGroupColumn";
import CalculatorInputLabel from "../CalculatorInputLabel";
import CalcualtorNumericInput from "../CalculatorNumericInput";
import CalculatorResultGroup from "../CalculatorResultGroup";
import PaymentMethodSelection from "../PaymentMethodSelection";
import CalculatorReadOnlyColumn from "../CalculatorReadOnlyColumn";
import CalculatorReadOnlyValue from "../CalculatorReadOnlyValue";

class NetPricePerWattCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethod: null,
            contractAmount: null,
            systemSize: null,
            totalAdders: null,
            totalAdditionalWork: null
        };
    }

    updatePaymentMethod(value) {
        this.setState({ "paymentMethod": value });
    }

    updateContractAmount(value) {
        this.setState({ "contractAmount": value });
    }

    updateSystemSize(value) {
        this.setState({ "systemSize": value });
    }

    updateTotalAdders(value) {
        this.setState({ "totalAdders": value });
    }

    updateTotalAdditionalWork(value) {
        this.setState({ "totalAdditionalWork": value });
    }

    getFees() {
        if (this.state.paymentMethod != null) {
            let priceWithoutAdders = this.state.contractAmount - Number(this.state.totalAdders);
            return priceWithoutAdders * (this.state.paymentMethod.fees);
        } else {
            return 0;
        }
    }

    getResult() {
        if (this.hasRequiredVariables()) {
            let priceWithoutAdders = this.state.contractAmount - Number(this.state.totalAdders);
            let loanFees = priceWithoutAdders * this.state.paymentMethod.fees;
            let netPrice = priceWithoutAdders - loanFees - Number(this.state.totalAdditionalWork);
            let netPricePerWatt = netPrice / this.state.systemSize;
            return netPricePerWatt.toFixed(2);
        }
        return "";
    }

    hasRequiredVariables() {
        return this.hasPaymentMethod() && this.hasContractAmount() && this.hasSystemSize();
    }

    hasPaymentMethod() {
        return this.state.paymentMethod != null && this.paymentMethodIsValid();
    }

    paymentMethodIsValid() {
        return this.state.paymentMethod["fees"] != undefined && typeof this.state.paymentMethod["fees"] == "number";
    }

    hasContractAmount() {
        return this.state.contractAmount != null && typeof this.state.contractAmount == "number";
    }

    hasSystemSize() {
        return this.state.systemSize != null && typeof this.state.systemSize == "number";
    }

    render() {
        return (
            <Calculator>
                <CalculatorInputGroup>
                    <CalculatorInputGroupRow>
                        <CalculatorInputGroupColumn>
                            <CalculatorInputLabel>Payment Method</CalculatorInputLabel>
                            <PaymentMethodSelection onChange={this.updatePaymentMethod.bind(this)} />
                        </CalculatorInputGroupColumn>
                        <CalculatorReadOnlyColumn isSmall>
                            <CalculatorInputLabel>Fees</CalculatorInputLabel>
                            <CalculatorReadOnlyValue prefix="$" value={this.getFees()}/>
                        </CalculatorReadOnlyColumn>
                    </CalculatorInputGroupRow>
                </CalculatorInputGroup>
                <CalculatorInputGroup>
                    <CalculatorInputGroupRow>
                        <CalculatorInputGroupColumn>
                            <CalculatorInputLabel>Contract Amount</CalculatorInputLabel>
                            <CalcualtorNumericInput onChange={this.updateContractAmount.bind(this)}/>
                        </CalculatorInputGroupColumn>
                        <CalculatorInputGroupColumn>
                            <CalculatorInputLabel>System Size</CalculatorInputLabel>
                            <CalcualtorNumericInput onChange={this.updateSystemSize.bind(this)}/>
                        </CalculatorInputGroupColumn>
                    </CalculatorInputGroupRow>
                    <CalculatorInputGroupRow>
                        <CalculatorInputGroupColumn>
                            <CalculatorInputLabel>Total Adders</CalculatorInputLabel>
                            <CalcualtorNumericInput onChange={this.updateTotalAdders.bind(this)}/>
                        </CalculatorInputGroupColumn>
                        <CalculatorInputGroupColumn>
                            <CalculatorInputLabel>Total Additional Work</CalculatorInputLabel>
                            <CalcualtorNumericInput onChange={this.updateTotalAdditionalWork.bind(this)}/>
                        </CalculatorInputGroupColumn>
                    </CalculatorInputGroupRow>
                </CalculatorInputGroup>
                <CalculatorResultGroup currency="$" value={this.getResult()} units={"per watt"}/>
            </Calculator>
        );
    }
}

export default NetPricePerWattCalculator;
