import "./CalculatorSearchInputOption.css";

function CalculatorSearchInputOption({ label, value, onSelect }) {
    return (
        <div className="CalculatorSearchInputOption" onClick={() => onSelect(label, value)}>
            <span className="CalculatorSearchInputOption-label">
                {label}
            </span>
        </div>
    );
}

export default CalculatorSearchInputOption;