import React from "react";
import "./CalculatorInput.css";
import "./CalculatorSearchInput.css";

class CalculatorSearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false,
            value: "",
        };
    }

    focus() {
        this.setState({ "isFocused": true });
    }

    handleChange(event) {
        this.setState({ "value": event.target.value });
        this.props.onChange(event.target.value);
    }

    select(label, value) {
        this.setState({
            "isFocused": false,
            "value": label,
        });
        this.props.onSelect(value);
    }

    getChildren() {
        return React.Children.map(this.props.children, (child) => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { onSelect: this.select.bind(this) });
            }
        });
    }

    render() {
        return (
            <div className={["CalculatorInput", "CalculatorSearchInput", this.state.isFocused ? " focused" : ""].join(" ")}>
                <div className="CalculatorSearchInput-field" onClick={this.focus.bind(this)}>
                    <input type="text" onChange={this.handleChange.bind(this)} value={this.state.value}/>
                </div>
                <div className="CalculatorSearchInput-options">
                    {this.getChildren()}
                </div>
            </div>
        );
    }
}

export default CalculatorSearchInput;