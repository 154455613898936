import "./CalculatorReadOnlyColumn.css";

function CalculatorReadOnlyColumn(props) {
    return (
        <div className={`CalculatorReadOnlyColumn${props.isSmall ? " CalculatorReadOnlyColumn-small" : ""}`}>
            {props.children}
        </div>
    );
}

export default CalculatorReadOnlyColumn;