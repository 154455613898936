import React from "react";
import Calculator from "../Calculator";
import CalculatorInputGroup from "../CalculatorInputGroup";
import CalculatorInputGroupRow from "../CalculatorInputGroupRow";
import CalculatorInputGroupColumn from "../CalculatorInputGroupColumn";
import CalculatorInputLabel from "../CalculatorInputLabel";
import CalcualtorNumericInput from "../CalculatorNumericInput";
import CalculatorResultGroup from "../CalculatorResultGroup";
import PaymentMethodSelection from "../PaymentMethodSelection";

class AdditionalWorkCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentMethod: null,
            bidAmount: null
        };
    }

    updatePaymentMethod(value) {
        this.setState({ "paymentMethod": value });
    }

    updateBidAmount(value) {
        this.setState({ "bidAmount": value });
    }

    getResult() {
        if (this.hasRequiredVariables()) {
            let paymentMethodFeesComplement = 1 - this.state.paymentMethod.fees;
            let costOfAdditionalWork = this.state.bidAmount / paymentMethodFeesComplement;
            return costOfAdditionalWork.toFixed(2);
        }
    }

    hasRequiredVariables() {
        return this.hasPaymentMethod() && this.hasBidAmount();
    }

    hasPaymentMethod() {
        return this.state.paymentMethod != null && this.paymentMethodIsValid();
    }

    paymentMethodIsValid() {
        return this.state.paymentMethod["fees"] != undefined && typeof this.state.paymentMethod["fees"] == "number";
    }

    hasBidAmount() {
        return this.state.bidAmount != null && typeof this.state.bidAmount == "number";
    }

    render() {
        return (
            <Calculator>
                <CalculatorInputGroup>
                    <CalculatorInputGroupRow>
                        <CalculatorInputGroupColumn>
                            <CalculatorInputLabel>Payment Method</CalculatorInputLabel>
                            <PaymentMethodSelection onChange={this.updatePaymentMethod.bind(this)} />
                        </CalculatorInputGroupColumn>
                    </CalculatorInputGroupRow>
                    <CalculatorInputGroupRow>
                        <CalculatorInputGroupColumn>
                            <CalculatorInputLabel>Bid Amount</CalculatorInputLabel>
                            <CalcualtorNumericInput onChange={this.updateBidAmount.bind(this)}/>
                        </CalculatorInputGroupColumn>
                    </CalculatorInputGroupRow>
                </CalculatorInputGroup>
                <CalculatorResultGroup currency="$" value={this.getResult()}/>
            </Calculator>
        );
    }
}

export default AdditionalWorkCalculator;