import React from "react";
import "./CalculatorReadOnlyValue.css";

class CalculatorReadOnlyValue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMouseDown: false
        }
    }

    getPrefix() {
        if (this.props.prefix != undefined) {
            return this.props.prefix;
        } else {
            return "";
        }
    }

    getValue() {
        if (this.props.value != undefined) {
            switch (typeof this.props.value) {
                case "number":
                    return Number(this.props.value).toLocaleString("en-US");
                default:
                    return this.props.value;
            }
        } else {
            return "";
        }
    }

    getSuffix() {
        if (this.props.suffix != undefined) {
            return this.props.suffix;
        } else {
            return "";
        }
    }

    hasValue() {
        return this.props.value != undefined && this.props.value != null && this.props.value != "";
    }

    copyToClipboard() {
        if (this.hasClipboardAccess()) {
            if (this.hasValue()) {
                navigator.clipboard.writeText(this.props.value.toString());
            } else {
                navigator.clipboard.writeText("0");
            }
        }
    }

    hasClipboardAccess() {
        return navigator != undefined && navigator.clipboard != undefined && navigator.clipboard.writeText != undefined;
    }

    handleMouseDown() {
        this.setState({ "isMouseDown": true });
    }

    handleMouseUp() {
        this.setState({ "isMouseDown": false });
    }

    render() {
        return (
            <div
                className={`CalculatorReadOnlyValue noselect${this.state.isMouseDown ? " lighten" : ""}`}
                onClick={this.copyToClipboard.bind(this)}
                onMouseDown={this.handleMouseDown.bind(this)}
                onMouseUp={this.handleMouseUp.bind(this)}
            >
                <span className="CalculatorReadOnlyValue-prefix">{this.getPrefix()}</span>
                <span className="CalculatorReadOnlyValue-value">{this.getValue()}</span>
                <span className="CalculatorReadOnlyValue-suffix">{this.getSuffix()}</span>
            </div>
        );
    }
}

export default CalculatorReadOnlyValue;