import React from "react";
import "./Calculator.css";

class Calculator extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="Calculator">
                {this.props.children}
            </div>
        );
    }
}

export default Calculator;