import "./CalculatorInputGroupRow.css";

function CalculatorInputGroupRow(props) {
    return (
        <div className="CalculatorInputGroupRow">
            <div className="CalculatorInputGroupRow-container">
                {props.children}
            </div>
        </div>
    );
}

export default CalculatorInputGroupRow;