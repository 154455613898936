import "./CalculatorInputGroupColumn.css";

function CalculatorInputGroupColumn(props) {
    return (
        <div className="CalculatorInputGroupColumn">
            {props.children}
        </div>
    );
}

export default CalculatorInputGroupColumn;