import React from "react";
import "./MenuItem.css";

class MenuItem extends React.Component {
    constructor(props) {
        super(props);
    }

    handleClick() {
        this.props.onClick(this.props.link);
    }

    render() {
        return (
            <div className="MenuItem" onClick={this.handleClick.bind(this)}>
                {this.props.title}
            </div>
        );
    }
}

export default MenuItem;