import React from "react";
import "./App.css";
import NetPricePerWattCalculator from "./Calculators/NetPricePerWatt/NetPricePerWattCalculator";
import AdditionalWorkCalculator from "./Calculators/Additional Work Calculator/AdditionalWorkCalculator";
import Menu from "./Menu/Menu";
import backArrow from "./back-arrow.svg";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      screen: "menu"
    };
  }

  changeScreen(screen) {
    this.setState({ "lastScreen": this.state.screen, "screen": screen });
  }

  changeScreenToMenu() {
    this.setState({ "screen": "menu" });
  }

  render() {
    return (
      <div className="App">
        <div className={["App-back-arrow", , this.state.screen == "menu" ? "hide" : ""].join(" ")}>
          <div className={"App-back-arrow-container"} onClick={this.changeScreenToMenu.bind(this)}>
              <img src={backArrow} alt="" width="25px" />
          </div>
        </div>
        {this.state.screen == "menu" ? (
          <Menu onChangeScreen={this.changeScreen.bind(this)} />
        ) : this.state.screen == "net-ppw" ? (
          <NetPricePerWattCalculator/>
        ) : this.state.screen == "additional-work" && (
          <AdditionalWorkCalculator/>
        )}
      </div>
    );
  }
}

export default App;
