import "./CalculatorInputLabel.css";

function CalculatorInputLabel(props) {
    return (
        <div className="CalculatorInputLabel">
            <span className="CalculatorInputLabel-text">{props.children}</span>
        </div>
    );
}

export default CalculatorInputLabel;